import riot from 'riot'
 riot.tag2('ctd-button', '<a if="{tag==\'a\'}" ref="root"> <raw html="{html}"></raw> </a> <button if="{tag!=\'a\'}" ref="root"> <raw html="{html}"></raw> </button>', '', '', function(opts) {

        this.mixin('component')

        const init = () => {
            this.attrs.class = 'btn'
            if (this.type) this.attrs.class += ` btn-${this.type}`
            if (this.size) this.attrs.class += ` btn-${this.size}`
        }

        init()

        this.on('update', () => {
            init()
        })

});