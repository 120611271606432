import riot from 'riot'
 riot.tag2('td-textarea', '<div class="form-group {invalid ? \'is-invalid\' : \'\'}"> <label if="{opts.label}">{opts.label}</label> <div class="input-group"> <div class="input-group-prepend"> <yield from="prepend"></yield> </div> <textarea autocomplete="off" ref="{opts.inputRef || \'input\'}" type="{opts.type || \'text\'}" required="{opts.required}" placeholder="{opts.placeholder}" class="form-control {opts.class}" rows="{opts.rows || 4}" onblur="{opts.onblur}" disabled="{opts.disabled}" onkeyup="{opts.onkeyup}"></textarea> <div class="input-group-append"> <yield from="append"></yield> </div> </div> <div class="invalid-feedback animated fadeIn"> {invalidMessage} </div> </div>', '', '', function(opts) {

        this.mixin('model')

        this.on('mount', () => {
            this.getInputRef().addEventListener('keyup', (e) => {

                if (event.keyCode === 13 && this.opts.onenter) {
                    e.preventDefault()
                    this.opts.onenter(e.target.value)
                }

                this.changeValue(e)
            })
        })

        this.changeValue = (e) => {
            this.update({ value: e.target.value })
        }

});