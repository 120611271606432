
import riot from 'riot'

import kebabCase from 'lodash/kebabCase'
import merge from 'lodash/merge'

riot.mixin('component', { 
       
    init(){
        this.attrs = { class: '' }
        this.styles = { }

        let cacheOpts = JSON.stringify(this.opts)
                
        this.extractOpts()
        
        this.extractData()

        this.mixin('styles')
        
        this.on('mount', () => {
            this.componentMount()
        })

        this.on('update', (data) => {
            this.componentUpdate(data)
            cacheOpts = JSON.stringify(this.opts)
        })

        this.shouldUpdate = (data, nextOpts) => {
            if(data && data.forced) {
                return true
            }            
            return cacheOpts != JSON.stringify(nextOpts)
        }

        this.on('unmout', () => {
            this.destroyRootStyles()
            this.destroyGlobalRule()
        })
    },

    extractOpts() {
        Object.keys(this.opts).forEach((key) => {
            if (this[key] && typeof this[key] === 'object') {
                this[key] = { ...this[key], ...this.opts.data[key] }
            } else {
                this[key] = this.opts.data[key]
            }
        })
    },

    extractData() {
        
        if (this.opts.data) {
            Object.keys(this.opts.data).forEach((key) => {
                if (this[key] && typeof this[key] === 'object') {
                    this[key] = {...this[key], ...this.opts.data[key]}
                } else {
                    this[key] = this.opts.data[key]
                }            
            })
        }
        
    },

    componentUpdate() {
        this.extractOpts()
        this.extractData()
        this.destroyRootStyles()
        this.destroyGlobalRule()        
        this.componentMount()        
    },

    componentMount() {

        if (!this.refs.root) return console.error('the root tag is missing')
        const attrs = {}

        this.styledClass = this.createGlobalRule(this.styles)

        Object.keys(this.attrs).forEach((key) => {
            attrs[kebabCase(key)] = this.attrs[key]
        })

        $(this.refs.root)
            .attr(attrs)
            .addClass(this.styledClass)
    }
})
