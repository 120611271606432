
import { CreateService } from './base'

const UploadService = new CreateService('/upload')

UploadService.image = async (file, extra = {}) => {

    var data = new FormData()
    data.append('image', file)

    Object.keys(extra).forEach((key) => {
        data.append(key, extra[key])
    })
    
    const result = await UploadService.path('/image', {}).post(data)

    return result
}

export { UploadService }