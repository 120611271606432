
import { CreateService } from './base'

const DepartmentService = new CreateService('/department')

DepartmentService.book = async (data) => {
    return await DepartmentService.path('/book', {}).post({
        list: data
    })
}

export { DepartmentService }