import riot from 'riot'
 import './input.scss'
import moment from 'moment'
riot.tag2('td-input', '<div class="form-group{invalid ? \' is-invalid\' : \'\'}{\' input-type-\' + (opts.type || \'text\')}"> <label if="{opts.label}" for="{inputId}">{opts.label}</label> <div class="input-group"> <div class="input-group-prepend"> <yield from="prepend"></yield> </div> <input id="{inputId}" autocomplete="{opts.autocomplete || \'off\'}" ref="{opts.inputRef || \'input\'}" type="{type || \'text\'}" name="{opts.name}" required="{opts.required}" placeholder="{opts.placeholder}" class="form-control {opts.class}" onblur="{opts.onblur}" disabled="{opts.disabled}" min="{opts.min}" max="{opts.max}" minlength="{opts.minlength}" maxlength="{opts.maxlength}" onkeydown 19 onkeyup="{opts.onkeyup}"> <div if="{opts.type!=\'color\'}" class="input-group-append"> <yield from="append"></yield> </div> <div if="{opts.type==\'color\'}" class="input-group-append input-group-addon"> <div class="input-group-text"> <i></i> </div> </div> </div> <div class="invalid-feedback animated fadeIn"> {invalidMessage} </div> </div>', '', '', function(opts) {
        this.mixin('model')

        this.type = opts.type

        if (this.type == 'color') {
            this.type = 'text'
        }

        this.on('modelChange', (data) => {
            if (typeof data === "string" && opts.type === 'money') {
                this.value = parseFloat(data.replace(/\./g, '').replace(',', '.'))
                this.value = this.value.toFixed(2)
            }
        })

        let firstChange = true

        this.on('viewChange', (data) => {
            if (firstChange && opts.type === 'date') {
                $(this.getInputRef()).val(data)
            }

            firstChange = false
        })

        this.on('mount', () => {
            if (opts.mask) {
                $(this.getInputRef()).mask(opts.mask, opts.maskOptions || {
                    reverse: true
                })
            }

            if (opts.type === 'money') {
                $(this.getInputRef()).mask('#.##0,00', { reverse: true })
            }

            if (opts.type === 'color') {
                $(this.getInputRef()).parent().colorpicker();
            }

            if (opts.type === 'money') {
                $(this.getInputRef()).mask('#.##0,00', { reverse: true })
            }

            if (opts.type === 'slider') {
                $(this.getInputRef()).ionRangeSlider(opts.sliderOptions || {})
            }

            if ( opts.autofocus ) {
                if (typeof opts.autofocus == 'function') {
                    opts.autofocus()
                } else {
                    this.getInputRef().focus()
                }
            }

            $(this.getInputRef()).on('input', (e) => {

                const code = e.keyCode || e.which
                if (code === 13 && this.opts.onenter) {
                    e.preventDefault()
                    this.opts.onenter(e.target.value)
                }

                this.changeValue(e)
            })

            if (['date', 'number'].indexOf(opts.type) > -1) {

                this.getInputRef().addEventListener('change', (e) => {
                    this.changeValue(e)
                })
                $(this.getInputRef()).val(this.viewValue)
            }

        })

        this.changeValue = (e) => {
            this.update({ value: e.target.value })
        }

});