export default class UniversalUtmTracker {

  init() {
    this.setUtmz();
  }

  getUrlParameter(search_param) {
    var url_part = window.location.search.substring(1);
    var decoded_url_part = decodeURIComponent(url_part);
    var params = decoded_url_part.split('&');

    for (var i = 0; i < params.length; i++) {
      var param = params[i].split('=');
      if (search_param == param[0]) {
        return param[1];
      }
    }
  }

  setCookie(cname, cvalue, exseconds) {
    var d = new Date();
    d.setTime(d.getTime() + (exseconds * 1000));
    var expires = 'expires=' + d.toUTCString();
    document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
  }

  getCookie(cname) {
    var name = cname + '=';
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  }

  utmzClassifier() {
    var utm_source = this.getUrlParameter('utm_source');
    var utm_medium = this.getUrlParameter('utm_medium');
    var utm_campaign = this.getUrlParameter('utm_campaign');
    var utm_content = this.getUrlParameter('utm_content');

    if (utm_source || utm_medium) {
      return '00000000.0000000000.00.00.utmcsr='+utm_source+'|utmccn='+utm_campaign+'|utmcmd='+utm_medium+'|utmcct='+utm_content;
    }
    if (!document.referrer) { 
      return '00000000.0000000000.00.00.utmcsr=direct|utmccn=|utmcmd=(not set)|utmcct=';
    }
    if (document.referrer.indexOf('google.com') > -1) {
      return '00000000.0000000000.00.00.utmcsr=google|utmccn=|utmcmd=organic|utmcct=';
    }
    return '00000000.0000000000.00.00.utmcsr='+document.referrer+'|utmccn=|utmcmd=referral|utmcct=';
  }

  setUtmz() {
    var utm_params = this.getCookie('utm_params');
    var utm_source = this.getUrlParameter('utm_source');
    var utm_medium = this.getUrlParameter('utm_medium');
    if (!utm_params || utm_source || utm_medium) {
      var c_utmz = this.utmzClassifier();
      this.setCookie('utm_params', c_utmz, 1800);
    }
  }

  getUtmz() {
    return this.getCookie('utm_params') || this.utmzClassifier();
  }
}