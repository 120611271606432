import riot from 'riot'
 import pathToRegexp from 'path-to-regexp'
riot.tag2('route', '<div if="{show}" class="{animation ? ⁗animated fadeIn⁗ : ⁗⁗}"> <yield></yield> </div>', '', '', function(opts) {
        let basePath = ''
        let path = opts.path

        this.animation = window.location.pathname.indexOf('/app') === 0

        const enter = (ctx, next) => {
            this.ctx = ctx
            window.ctx = ctx
            this.update({show: true})

            if (this.sidebar)
                this.sidebar.setActive(ctx.path)

            if (this.header)
                this.header.setActive(ctx.path)

            if (this.configMenu)
                this.configMenu.setActive(ctx.path)

            if (opts.redirect) {
                let toPath = pathToRegexp.compile(this.router.opts.base + this.parentRoute.fullPath.replace(/\/$/g, '') + opts.redirect)
                this.router.page(toPath(ctx.params))
                return
            }

            if (opts.abstract) {
               return next()
            }

            if (!opts.abstract && opts.disableScroll) {
                window.scrollTo(0, 0)
            }
        }

        const exit = (ctx, next) => {
            const regex = pathToRegexp(this.fullPath.replace(/\/$/g, '') + '(.*)')
            if (!regex.exec(ctx.page.current))
                this.update({show: false})
            else if (!opts.abstract)
                this.update({show: false})
            next()
        }

        this.on('mount', () => {

            if (opts.abstract) {
                path += '*'
            }

            let parentRoute = this.parent

            while(parentRoute != null) {
                if (parentRoute && parentRoute.root.tagName === 'ROUTER') {
                    this.router = parentRoute
                    break;
                }
                if (parentRoute && parentRoute.opts && parentRoute.opts.abstract) {
                    basePath = parentRoute.opts.path + basePath

                    if (!this.parentRoute)
                        this.parentRoute = parentRoute
                }
                parentRoute = parentRoute.parent
            }

            this.fullPath = basePath + opts.path
            this.router = this.router || globalRouter
            this.router.addRoute(basePath + path, enter, this)
            this.router.addRoute.exit(basePath + path, exit)

        })

        this.on('unmount', () => {
            this.router.removeRoute(path, enter)
            this.router.removeRoute.exit(path, enter)
        })
});