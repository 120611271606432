import riot from 'riot'
 import icon from '@course/assets/images/td-premium/logos/td.svg'
riot.tag2('purchase-notification', '<div if="{show && message}" onclick="{close}" class="purchase-notification animated fadeInUp"> <div class="user-image"> <div class="user-image-bg"> <img riot-src="{icon}"> </div> </div> <div class="user-text"> <div class="user-name"> {message.name} {message.surname} {message.state.separator} {message.state.name} </div> <div if="{course}" class="user-desc"> {course.purchase.sentence} </div> </div> </div>', '', '', function(opts) {

        let count = 0.8
        let minCount = 0.3

        this.message = {}
        this.show = false
        this.icon = icon

        const StatesData = [
            {name: 'Distrito Federal', separator: 'do'},
            {name: 'Espírito Santo', separator: 'do'},
            {name: 'Goiás', separator: 'de'},
            {name: 'Mato Grosso', separator: 'do'},
            {name: 'Mato Grosso do Sul', separator: 'do'},
            {name: 'Minas Gerais', separator: 'de'},
            {name: 'Pernambuco', separator: 'de'},
            {name: 'Rio de Janeiro', separator: 'do'},
            {name: 'Rio Grande do Sul', separator: 'do'},
            {name: 'Santa Catarina', separator: 'de'},
            {name: 'São Paulo', separator: 'de'}
        ]

        let timeout

        this.close = () => {
            this.show = false
            this.update()
            nextMessage()
            clearTimeout(timeout)
        }

        const createMessage = () => {
            this.show = true
            count += 0.6
            minCount += 0.3

            $.get('https://uinames.com/api/?region=brazil', (data) => {
                const state = Math.floor((Math.random() * (StatesData.length -1)))
                this.message = data
                this.message.state = StatesData[state]
                this.update()
                timeout = setTimeout(() => {
                    this.close()
                }, 15000)
            })
        }

        const nextMessage = () => {
            const timer = (Math.random() * count) + minCount;
            setTimeout(() => {
                createMessage()
            }, Math.floor(timer * 1000 * 60))
        }

        nextMessage()
});