
import riot from 'riot'

import jss from 'jss'
import preset from 'jss-preset-default'

jss.setup(preset())

let globalStyle = null

let countRule = 0

let countSheets = 0

riot.mixin('styles', {

    createStyles(styles, ref=false) {
        const style = jss.createStyleSheet(styles).attach()  

        if (ref) {
            return style
        }
        
        return style.classes        
    },

    createRootStyles(styles, ref=false) {

        let newStyles = {}

        if (this.attrs && this.attrs.class) {
            const nested = this.attrs.class.split(' ').join('.')
            newStyles = {[this.__.tagName]: { ['&'+nested]: styles }}
        } else {
            newStyles = {[this.__.tagName]: styles}
        }

        countSheets++

        this.__styles = jss.createStyleSheet(newStyles, {index: countSheets, meta: 'sheet-' + countSheets})  

        this.__styles.attach()

        if (ref) {
            return this.__styles
        }
        
        return this.__styles.classes[this.__.tagName]  
    },

    createGlobalRule(styles, ref=false) {  

        if (!globalStyle) {
            globalStyle = jss.createStyleSheet({}).attach()
        }

        countRule++

        const className = this.__.tagName + '-' + countRule
        let rule

        if (this.attrs && this.attrs.class) {
            const nested = this.attrs.class.split(' ').join('.')
            rule = globalStyle.addRule(className, {
                ['&.'+nested]: styles
            })
        } else {
            rule = globalStyle.addRule(className, styles)
        }   

        this.__rule = rule
       
        return rule.options.classes[className]
    },

    destroyRootStyles() {
        if (this.__styles)       
            jss.removeStyleSheet(this.__styles)
    },

    destroyGlobalRule() {
        if (this.__rule) 
            globalStyle.deleteRule(this.__rule.key)
    }
})
