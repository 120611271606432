import riot from 'riot'
 import './timer-b.scss'
riot.tag2('timer-b', '<section class="container content"> <ctd-text data="{data.title}"></ctd-text> <div class="timer mt-30"> <div class="timer-numbers"> <div class="timer-number" if="{this.days!=\'00\'}"> <div class="number">{days}</div> <div class="label">Dias</div> </div> <div class="timer-space" if="{this.days!=\'00\'}">:</div> <div class="timer-space">:</div> <div class="timer-number"> <div class="number">{hours}</div> <div class="label">Horas</div> </div> <div class="timer-space">:</div> <div class="timer-number"> <div class="number">{minutes}</div> <div class="label">Minutos</div> </div> <div class="timer-space">:</div> <div class="timer-number"> <div class="number">{seconds}</div> <div class="label">Segundos</div> </div> </div> </div> <div class="text-center mt-30" if="{data.button}"> <ctd-button data="{data.button}"></ctd-button> </div> </section>', '', '', function(opts) {

        this.data = opts.data

        const countDownDate = this.course.timer

        this.days = '00'
        this.hours = '00'
        this.minutes = '00'
        this.seconds = '00'

        const tick = () => {

            if (countDownDate == 'auto') {
                const now = new Date()

                const hours = 24 - now.getHours()
                const minutes = 59 - now.getMinutes()
                const seconds = 59 - now.getSeconds()

                this.hours = ('0'+hours).slice(-Math.max((''+hours).length, 2))
                this.minutes = ('0'+minutes).slice(-Math.max((''+minutes).length, 2))
                this.seconds = ('0'+seconds).slice(-Math.max((''+seconds).length, 2))

                this.update()

                return;
            }

            const now = new Date().getTime()

            const distance = countDownDate - now

            const days = Math.floor(distance / (1000 * 60 * 60 * 24))
            const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
            const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
            const seconds = Math.floor((distance % (1000 * 60)) / 1000)

            this.days = ('0'+days).slice(-Math.max((''+days).length, 2))
            this.hours = ('0'+hours).slice(-Math.max((''+hours).length, 2))
            this.minutes = ('0'+minutes).slice(-Math.max((''+minutes).length, 2))
            this.seconds = ('0'+seconds).slice(-Math.max((''+seconds).length, 2))

            this.update()

            if (distance < 0) {
                clearInterval(timer)
            }
        }

        tick()

        const timer = setInterval(tick, 1000)
});