
import { CreateService } from './base'
import store from '@td-premium/store'

const AuthService = new CreateService('/auth')

AuthService.login = async (email, password) => {

    const result = await AuthService.path('/login', {}).post({
        email, password
    })

    store.set('session', result)
    store.set('loggedIn', true) 
    store.set('token', result.token)
    
    return result
}

AuthService.logout = async (email, password) => {     

    let result = false

    try {
        result = await AuthService.path('/logout', {token: true}).get()
    } catch (e) {}

    store.remove('session')
    store.remove('loggedIn')
    store.remove('token')

    return result
}

AuthService.signup = async (data) => {

    const result = await AuthService.path('/signup', {}).post(data)

    store.set('session', result)
    store.set('loggedIn', true)
    store.set('token', result.token)    

    return result
}

AuthService.payment = async (data) => {

    const result = await AuthService.path('/payment', {}).post(data)
    store.remove('token') 
    store.set('token', result.token)    
    return result
}

AuthService.forgotPassword = async (data) => {
    return await AuthService.path('/forgot-password', {}).post(data)
}

AuthService.resetPassword = async (data) => {
    return await AuthService.path('/reset-password', {}).post(data)
}

AuthService.checkResetPassword = async (data) => {
    return await AuthService.path('/check-reset-password', {}).post(data)
}

AuthService.checkInvitation = async (data) => {
    return await AuthService.path('/check-invitation', {}).post(data)
}

AuthService.confirmation = async (data) => {
    return await AuthService.path('/confirmation', {}).post(data)
}

AuthService.check = async () => {
    try {
        const result = await AuthService.path('/check', {}).get()
        store.set('session', result)
        store.set('loggedIn', true)
        store.set('token', result.token)
        return result
    } catch (error) {
        if (error.message === 'Invalid Token' || error.code == '500' || error.code == '201') {
            store.remove('session')
            store.remove('loggedIn')
            store.remove('token')
            window.location = '/app'
        }
    }  

    return false;
}

export { AuthService }