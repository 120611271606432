import riot from 'riot'
 import { UploadService } from '@common/services'
riot.tag2('td-upload-image', '<div class="form-group"> <label if="{opts.label}">{opts.label}</label> <input ref="{opts.inputRef || \'input\'}" type="file"> <div class="drop-area" riot-style="{opts.dropStyle}" ondrop="{dropHandler}" ondragover="{dragOverHandler}"> <virtual if="{!value}"> <span> Arraste sua imagem aqui </span> <button type="button" onclick="{choiceImage}" class="btn btn-primary mt-5"> Escolher Imagem </button> </virtual> <div if="{loading}" class="loading"> <i class="fa fa-4x fa-spinner fa-spin text-info"></i> </div> <div if="{value}" class="options-container fx-item-zoom-in fx-overlay-slide-down"> <img riot-style="{opts.imgStyle}" class="img-fluid options-item" riot-src="{value}"> <div class="options-overlay bg-black-op"> <div class="options-overlay-content"> <a onclick="{choiceImage}" class="btn btn-sm btn-rounded btn-alt-primary min-width-75" href=""> <i class="fa fa-pencil"></i> Alterar </a> <a onclick="{removeImage}" class="btn btn-sm btn-rounded btn-alt-danger min-width-75" href=""> <i class="fa fa-times"></i> Delete </a> </div> </div> </div> </div> </div>', 'td-upload-image .drop-area,[data-is="td-upload-image"] .drop-area{ border: 1px dashed #2facb2; display: flex; flex-direction: column; justify-content: center; align-items: center; min-height: 150px; position: relative; } td-upload-image .drop-area .options-container,[data-is="td-upload-image"] .drop-area .options-container{ flex: 1; width: 100%; display: flex; flex-direction: column; justify-content: center; align-items: center; } td-upload-image .drop-area .loading,[data-is="td-upload-image"] .drop-area .loading{ flex: 1; width: 100%; height: 100%; display: flex; justify-content: center; align-items: center; position: absolute; z-index: 2; background: rgba(0,0,0,.5); } td-upload-image [type="file"],[data-is="td-upload-image"] [type="file"]{ width: 0px; height: 0px; display: none; }', '', function(opts) {

        this.mixin('model')

        this.on('mount', () => {

            const input = this.getInputRef()

            input
            .addEventListener('change', async (e) => {

                var file = input.files[0]
                var imageType = /image.*/

                if (!file) return

                if (file.type.match(imageType)) {
                    var reader = new FileReader();

                    reader.onload = (e) => {
                        this.value = reader.result
                        this.update();
                    }

                    reader.readAsDataURL(file)
                    this.loading = true
                    try {
                        const result = await UploadService.image(file, opts.data)
                        this.setModelValue(result[0])
                    } catch (error) {
                        console.log(error)
                    }

                    setTimeout(() => {
                        this.loading = false
                        this.update()
                    }, 1000)
                }
            })
        })

        this.dropHandler = (e) => {
            console.log('Drop', e)
            e.preventDefault()
        }

        this.dragOverHandler = (e) => {
            e.preventDefault()
        }

        this.choiceImage = (e) => {
            e.preventDefault()
            this.getInputRef().click()
        }

        this.removeImage = (e) => {
            e.preventDefault()
            this.getInputRef().value = ''
            this.setModelValue(null)
            this.update()
        }

});