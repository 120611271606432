import riot from 'riot'
 riot.tag2('raw', '', '', '', function(opts) {

        const render = () => {
            this.root.innerHTML = opts.html
        }

        render()

        this.shouldUpdate = (data, nextOpts) => {
            return JSON.stringify(opts) != JSON.stringify(nextOpts)
        }

        this.on('update', render)

        this.on('mount', () => {
            this.root.removeAttribute('html')
        })
});