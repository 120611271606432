import riot from 'riot'
 import './header.scss'
import './header-c.scss'
riot.tag2('header-c', '<header id="page-header" if="{menu}"> <div class="content-header" riot-style="{menuStyles}"> <div class="content-header-section"> <a class="brand" href=""> <ctd-image data="{menu.brand}"></ctd-image> </a> </div> <div class="content-header-section"> <div class="menu" if="{menu.items}"> <a each="{item in menu.items}" riot-style="{convertStyles(item.styles)}" href="{item.link}" class="btn btn-dual-secondary"> {item.text} </a> </div> <ctd-button if="{menu.button}" data="{menu.button}"></ctd-button> </div> </div> </header> <ctd-image data="{header.image}" class="image-header"></ctd-image> <div class="bg-image" riot-style="{headerStyles}"> <video autoplay muted loop id="video"> <source riot-src="{this.video}" type="video/mp4"> </video> <div class="container content content-full content-top pt-20 h-100 d-flex flex-column justify-content-center align-items-start"> <ctd-text data="{header.title}"></ctd-text> <ctd-text data="{header.subtitle}"></ctd-text> <div class="row d-flex flex-row justify-content-between"> <ctd-button data="{header.button}" if="{header.button}"></ctd-button> <ctd-button data="{header.button2}" if="{header.button2}"></ctd-button> </div> <ctd-text data="{header.date}"></ctd-text> </div> </div> <div class="made-by"> <div class="d-flex flex-row align-items-center"> <ctd-text data="{header.made_by.title}" if="{header.made_by.title}"></ctd-text> <ctd-image each="{logo in header.made_by.logos}" if="{logo}" data="{logo}"></ctd-image> </div> </div>', '', '', function(opts) {

        if (window.innerWidth >= 680) {
            this.video = opts.data.video
        } else {
            this.video = opts.data.videoMobile
        }

        this.header = opts.data.header
        this.menu = opts.data.menu
        if (this.menu) {
            this.menuStyles = this.convertStyles(opts.data.menu.styles || {})
        }

        this.headerStyles = this.convertStyles(opts.data.header.styles || {})

});