
import { CreateService } from './base'

const CheckoutService = new CreateService('/checkout')

CheckoutService.payment = async (data) => {    
    return await CheckoutService.path('/payment', {}).post(data)
}

CheckoutService.addToCart = async (data) => {    
    return await CheckoutService.path('/addtocart', {}).post(data)
}

CheckoutService.subscription = async (data) => {    
    return await CheckoutService.path('/subscription', {}).post(data)
}

CheckoutService.customer = {
    subscription: async (data) => {    
        return await CheckoutService.path('/customer/subscription', {}).post(data)
    }
}

export { CheckoutService }