
import { CreateService } from '../base'
import store from '@td-premium/store'


const SubscriptionService = new CreateService('/billing/subscription')

SubscriptionService.current = SubscriptionService.path('/current', {})

SubscriptionService.current.cancel = async (data) => {
    return await SubscriptionService.current.path('/cancel', {}).post(data)
}

SubscriptionService.current.reprocess = async (data) => {
    const result = await SubscriptionService.current.path('/reprocess', {}).post(data)

    store.set('session', result)
    store.set('loggedIn', true)
    store.set('token', result.token)
    // store.set('user', result.user)
    // store.set('loggedIn', true)
    // store.set('type', result.type)
    // store.set('active', result.active)
    // store.set('pending', result.pending)

    return result
}

export { SubscriptionService }