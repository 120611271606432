import riot from 'riot'
 import pageJs from 'page'
import pathToRegexp from 'path-to-regexp'
riot.tag2('router', '<virtual show="{!notfound}"><yield></yield></virtual> <error-404 if="{notfound}"></error-404>', '', '', function(opts) {

        pageJs.start()
        pageJs.stop()

        window.page = pageJs

        this.page = pageJs.create()

        window._page = this.page

        window.routers = {}

        this.addRoute = function(path, fn, instance) {
            _page(path, fn)
            const route = _page.callbacks[_page.callbacks.length - 1]
            fn.route = route
            window.routers[path] = instance
        }

        this.addRoute.exit = function(path, fn) {
            _page.exit(path, fn)
            const route = _page.exits[_page.exits.length - 1]
            fn.route = route
        }

        this.removeRoute = function(path, fn) {
            const index = _page.callbacks.indexOf(fn.route)
            if (index === -1)
                return
            _page.callbacks.splice(index, 1)
        }

        this.removeRoute.exit = function(path, fn) {
            const index = _page.exits.indexOf(fn.route)
            if (index === -1)
                return
            _page.exits.splice(index, 1)
        }

        _page.base(opts.base || '')

        _page('/*', async (ctx, next) => {
            this.ctx = ctx

            if (opts.resolve)
                if(!await opts.resolve(ctx))
                    return
            next()
        })

        this.on('mount', () => {
            setTimeout(() => {
                if (window.oldPage)
                    window.oldPage.stop()

                _page('/*', (ctx, next) => {
                    this.notfound = false
                    this.update()
                    const routers = Object.keys(window.routers)
                    for (let i = 0; i < routers.length; i++) {
                        const path = routers[i]

                        if (path === '/' || path === '/*')
                            continue

                        const regexp = pathToRegexp(path.replace(/\/$/ig, ''))

                        if (regexp.exec(ctx.path))
                            return next()
                    }
                    this.notfound = true
                    this.update()
                })
                _page.start()
                window.oldPage = _page
            }, 0)

        })

        this.on('unmount', () => {
            _page.stop()
        })

        riot.mixin('router', this)
        window.globalRouter = this
});