import riot from 'riot'
 import showdown from 'showdown'
riot.tag2('td-markdown', '<div class="form-group {invalid ? \'is-invalid\' : \'\'}"> <label if="{opts.label}">{opts.label}</label> <div class="input-group"> <div class="input-group-prepend"> <yield from="prepend"></yield> </div> <div class="w-100"> <textarea autocomplete="off" ref="{opts.inputRef || \'input\'}" type="{opts.type || \'text\'}" required="{opts.required}" placeholder="{opts.placeholder}" class="{opts.class}" rows="{opts.row || 4}" onblur="{opts.onblur}" onkeyup="{opts.onkeyup}"></textarea> </div> <div class="input-group-append"> <yield from="append"></yield> </div> </div> <div class="invalid-feedback animated fadeIn"> {invalidMessage} </div> </div>', 'td-markdown .CodeMirror,[data-is="td-markdown"] .CodeMirror,td-markdown .CodeMirror-scroll,[data-is="td-markdown"] .CodeMirror-scroll{ min-height: 200px; } td-markdown .editor-preview strong,[data-is="td-markdown"] .editor-preview strong{ font-weight: bold; }', '', function(opts) {
        this.mixin('model')

        this.on('mount', () => {
            const input = this.getInputRef()

            this.editor = new SimpleMDE({
                toolbar: [
                    "bold", "italic", "heading", "|",
                    "quote", "unordered-list", "ordered-list", "|",
                    "link", "image", "|",
                    "preview"
                ],
                element: input,
                spellChecker: false,
                status: false,
                autosave: {
                    enabled: true,
                    delay: 1000,
                },
                previewRender: function(text) {
                    const converter = new showdown.Converter()
                    return converter.makeHtml(text.replace(/(?:\r\n|\r|\n)/g, '  \r\n'))
                }
            })

            this.editor.codemirror.on('change', () => {
                this.update({ value: this.editor.value() })
            })

            this.editor.codemirror.on('change', () => {
                if(this.editor.value() !== this.value)
                    this.update({ value: this.editor.value() })
            })

            this.on('viewChange', () => {
                if(this.editor.value() !== this.viewValue)
                    this.editor.value(this.viewValue || '')
            })
        })

});