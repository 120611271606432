export default {
    // 'introducao-a-transformacao-digital': async () => await import('./introducao-a-transformacao-digital'),
    'meu-negocio-digital': async () => await import('./meu-negocio-digital'),
    'meu-negocio-digital-exclusivo': async () => await import('./meu-negocio-digital-exclusivo'),
    'meu-negocio-digital-especial': async () => await import('./meu-negocio-digital-especial'),
    'especial': async () => await import('./especial'),
    'transforme-sua-agencia': async () => await import('./tsa'),
    'quero-vender-mais': async () => await import('./qvm'),
    'quero-vender-mais-2': async () => await import('./qvm2'),
    'quero-vender-mais-3': async () => await import('./qvm3'),
    'quero-vender-mais-2b': async () => await import('./qvm2b'),
    'quero-vender-mais-3b': async () => await import('./qvm3b'),
    'dtd': async () => await import('./dtd'),
    'digital-conference': async () => await import('./digital-conference'),
    'digital-conference-patrocinio': async () => await import('./digital-conference-patrocinio'),
    'digital-conference-palestrantes': async () => await import('./digital-conference-palestrantes'),
    // 'digital-conference-palestras': async () => await import('./digital-conference-stream'),
    'money-week': async () => await import('./money-week'),
    'money-week-palestrantes': async () => await import('./money-week-palestrantes'),
    'money-week-palestras': async () => await import('./money-week-stream'),
    'money-week-palestras-testes': async () => await import('./money-week-stream-testes'),
}