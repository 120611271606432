import riot from 'riot'
 import './header.scss'
riot.tag2('header-b', '<div class="bg-video" riot-style="{data.video.styles}"> <iframe src="https://www.youtube.com/embed/0ycMfetToA8?autoplay=1&controls=0" frameborder="0" allowfullscreen> </iframe> </div> <div class="container content"> <div ref="comments" data-width="100%" class="fb-comments mt-20" data-href="http://cursos.transformacaodigital.com/meu-negocio-digital" data-numposts="10"></div> </div>', '', '', function(opts) {

        this.data = opts.data

        this.on('mount', () => {
            if (FB) {
                FB.XFBML.parse(this.refs.commnets)
            }
        })
});