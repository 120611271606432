
import { CreateService } from './base'

const ReportService = new CreateService('/report')

ReportService.getType = async (type, query) => {
    return await ReportService.path('/' + type, {}).find(query)
}

ReportService.user = ReportService.path('/user', {})

ReportService.user.getType = async (type, query) => {
    return await ReportService.user.path('/' + type, {}).find(query)
}

ReportService.DIMENSIONS = {
    cultura_e_lideranca: {
        label: 'Cultura e Liderança'
    },
    agilidade_operacional: {
        label: 'Agilidade Operacional'
    },
    tecnologia_digital: {
        label: 'Tecnologia Digital'
    },
    foco_no_cliente: {
        label: 'Foco no Cliente'
    },
    visao_de_negocio: {
        label: 'Visão de Negócio'
    }
}

export { ReportService }