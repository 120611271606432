import riot from 'riot'
 riot.tag2('td-modal', '<div ref="modal" class="modal fade" tabindex="-1" role="dialog"> <div class="modal-dialog modal-dialog-centered" role="document"> <div class="modal-content"> <form ref="from" onsubmit="{onsubmit}"> <div class="block block-themed block-transparent mb-0"> <div class="block-header block-header-full bg-primary-dark"> <h3 class="block-title"> <yield from="title"></yield> </h3> <div class="block-options"> <button type="button" class="btn-block-option" data-dismiss="modal" aria-label="Close"> <i class="si si-close"></i> </button> </div> </div> <div class="block-content"> <yield from="content"></yield> </div> </div> <div class="modal-footer"> <yield from="footer"></yield> </div> </form> </div> </div> </div>', '', '', function(opts) {


        this.exec = (...args) => {
            $(this.refs.modal).modal(...args)
        }

        this.show = () => {
            $(this.refs.modal).addClass('show fadeInDown animated')
            $(this.refs.modal).css({display: 'block'})
            $(this.refs.modal).modal('show')
        }

        this.onsubmit = (e) => {
            if (opts.submit) {
                opts.submit(e)
            }
        }

        this.on('unmount', () => {
            $(this.refs.modal).modal('hide')
            $('.modal-backdrop').remove()
        })

        const updateData = () => {
            this.data = opts.data
        }
        updateData()
        this.on('update', updateData)
});