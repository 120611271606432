
import { CreateService } from '../base'

const ProductService = new CreateService('/billing/product')

ProductService.PRICING_TYPE = {
    FLAT: 'flat',
    PER_UNIT: 'per_unit'
}

ProductService.PRICING_TYPE_CHOICES = {
    [ProductService.PRICING_TYPE.FLAT]: 'Preço fixo',
    [ProductService.PRICING_TYPE.PER_UNIT]: 'Preço por quantidade'
}


ProductService.DISCOUNT_TYPE = {
    AMOUNT: 'amount',
    PERCENTAGE: 'percentage'
}

ProductService.DISCOUNT_TYPE_CHOICES = {
    [ProductService.DISCOUNT_TYPE.AMOUNT]: 'Valor (R$)',
    [ProductService.DISCOUNT_TYPE.PERCENTAGE]: 'Porcentagem (%)'
}

export { ProductService }