import riot from 'riot'
 import 'parchment'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.bubble.css'
import 'quill/dist/quill.snow.css'
import Quill from 'quill'
riot.tag2('td-html-editor', '<div class="form-group {invalid ? \'is-invalid\' : \'\'}"> <label if="{opts.label}">{opts.label}</label> <input id="{inputId}" autocomplete="off" style="display:none;" ref="{opts.inputRef || \'input\'}" type="{\'text\'}" required="{opts.required}" class="{opts.class}" rows="{opts.row || 4}" onblur="{opts.onblur}" onkeyup="{opts.onkeyup}"> <div ref="editor"></div> <div class="invalid-feedback animated fadeIn"> {invalidMessage} </div> </div>', 'td-html-editor .editor-preview strong,[data-is="td-html-editor"] .editor-preview strong{ font-weight: bold; } td-html-editor .ql-container,[data-is="td-html-editor"] .ql-container{ cursor: initial; min-height: 200px; } td-html-editor .ql-container .ql-editor,[data-is="td-html-editor"] .ql-container .ql-editor{ min-height: 200px; } td-html-editor .ql-container p,[data-is="td-html-editor"] .ql-container p{ margin: 0; } td-html-editor .ql-container .ql-clipboard,[data-is="td-html-editor"] .ql-container .ql-clipboard{ display: none; }', '', function(opts) {

        const Parchment = Quill.import('parchment')

        const Block = Parchment.query('block')
        Block.tagName = 'DIV'
        Quill.register(Block, true)

        this.mixin('model')

        const toolbarOptions = [
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            ['bold', 'italic', 'underline', 'strike'],
            [{ 'align': [] }, { 'list': 'ordered'}, { 'list': 'bullet' }, { 'indent': '-1'}, { 'indent': '+1' }],
            ['blockquote', 'code-block'],
            [{ 'color': [] }, { 'background': [] }]
        ];

        this.on('mount', () => {
            const input = this.getInputRef()

            this.editor = new Quill(this.refs.editor, {
                modules: {
                    toolbar: toolbarOptions
                },
                placeholder: opts.placeholder || '',
                theme: 'snow'
            })

            this.editor.on('text-change', () => {
                if(this.editor.container.firstChild.innerHTML !== this.value)
                    this.update({ value: this.editor.container.firstChild.innerHTML })
            })

            this.on('viewChange', () => {
                if(this.editor.container.firstChild.innerHTML !== this.viewValue)
                    this.editor.clipboard.dangerouslyPasteHTML(this.viewValue || '')
            })
        })

});