
import { CreateService } from './base'

const CategoryService = new CreateService('/category')

CategoryService.TYPE = {
    NORMAL: 1,
    TRACK: 2
}

CategoryService.TYPE_CHOICES = {
    [CategoryService.TYPE.NORMAL]: 'Normal',
    [CategoryService.TYPE.TRACK]: 'Trilha'
}

CategoryService.OPERATOR = {
    GTE: 'gte',
    LTE: 'lte'
}

CategoryService.OPERATOR_CHOICES = {
    [CategoryService.OPERATOR.GTE]: 'Maior ou igual',
    [CategoryService.OPERATOR.LTE]: 'Menor ou igual'
}


CategoryService.STATUS = {
    PUBLISHED: 1,
    DRAFT: 2
}

CategoryService.STATUS_CHOICES = {
    [CategoryService.STATUS.PUBLISHED]: 'Publicado',
    [CategoryService.STATUS.DRAFT]: 'Rascunho'
}

export { CategoryService }