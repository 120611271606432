
import { CreateService } from '../base'

const CustomerService = new CreateService('/billing/customer')

CustomerService.current = new CreateService('/billing/customer/current')

CustomerService.current.card = async () => {
    return await CustomerService.current.path('/card', {}).get()
}

CustomerService.current.updateCard = async (data) => {
    return await CustomerService.current.path('/card', {}).put(data)
}

CustomerService.current.bills = async () => {
    return await CustomerService.current.path('/bills', {}).get()
}

CustomerService.current.corporate = async (data) => {
    return await CustomerService.current.path('/corporate', {}).post(data)
}

CustomerService.TYPE = {
    DEFAULT: 'default',
    AGENCY: 'agency'
}

CustomerService.TYPE_CHOICES = {
    [CustomerService.TYPE.DEFAULT]: 'Padrão',
    [CustomerService.TYPE.AGENCY]: 'Agência'
}

export { CustomerService }