import riot from 'riot'
 import './local-a.scss'
import courses from '@root/courses'
riot.tag2('local-a', '<section class="container content pb-100 {selected_class}" id="{data.id}"> <div class="row justify-content-center"> <div class="col-md-5"> <div class="places"> <div class="header"> <ctd-text data="{data.header}" if="{data.header}"></ctd-text> </div> <div if="{data.options}"> <div class="option {selected == key ? \'selected\' : \'\'}" each="{option,key in data.options}" onclick="{setLocation(key)}"> <ctd-text data="{option.name}" if="{option.name}"></ctd-text> </div> </div> </div> </div> <div class="col-md-7 d-flex align-items-end justify-content-center flex-column" if="{data.options[selected]}"> <div class="logo"> <ctd-image if="{data.logo}" data="{data.logo}"></ctd-image> </div> <div class="place"> <ctd-text if="{data.options[selected].bigname}" data="{data.options[selected].bigname}"></ctd-text> </div> <div class="date"> <ctd-text if="{data.options[selected].date}" data="{data.options[selected].date}"></ctd-text> </div> <div class="button"> <ctd-button if="{data.options[selected].button}" data="{data.options[selected].button}"></ctd-button> </div> </div> </div> </section>', '', '', function(opts) {

        this.data = opts.data
        this.selected = 0
        this.selected_class = this.data.options[this.selected].class
        this.course.simpla_link = this.data.options[this.selected].link
        this.course.local = this.data.options[this.selected].local

        this.setLocation = (index) => {
            return () => {
                this.selected = index
                this.selected_class = this.data.options[this.selected].class
                this.course.simpla_link = this.data.options[this.selected].link
                this.course.local = this.data.options[this.selected].local
            }
        }

});