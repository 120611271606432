import riot from 'riot'
 riot.tag2('td-groupbox', '<div class="form-group row"> <label class="col-12" if="{opts.label}">{opts.label}</label> <div class="col-12"> <yield></yield> </div> </div>', '', '', function(opts) {

        if (!opts.model) return;

        this.refs[ opts.inputRef || 'input' ] = this.root

        this.mixin('model')

        this.setModelValue([])

        this.on('mount', () => {
            let checkboxs = this.tags['td-checkbox']
            checkboxs = Array.isArray(checkboxs) ? checkboxs : [checkboxs]

            checkboxs.forEach((tag) => {
                tag.getInputRef().addEventListener('change', (e) => {
                    this.value = this.value || []

                    if ($(e.target).is(':checked')) {
                        this.value.push($(e.target).val())
                    } else {
                        const index = this.value.indexOf($(e.target).val())
                        if (index > -1) {
                            this.value.splice(index, 1)
                        }
                    }

                    this.update({ value: this.value })
                })
            })
        })

});