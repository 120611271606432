import riot from 'riot'

import _debounce from 'lodash/debounce'
import _has from 'lodash/has'
import _get from 'lodash/get'
import _set from 'lodash/set'


const ITEMS_PER_PAGE = 50


riot.mixin('baseList', class BaseList {

    init() {

        this.mixin('infiniteScroll')

        this.on('nextPage', async () => {
            this.query.page = this._page
           
            const data = await this.service.find(this.query)

            if (data.length !== (this.query.limit || ITEMS_PER_PAGE) ) {
                this._initScroll = false            
            }

            data.map((item) => {
                this.data.push(item)
            })

            this.update({ data: this.data, _loading: false })
        })

        this.on('mount', () => {
            this.infiniteScroll(this.refs.scroll)
        })

        this.loadDataDebounce = _debounce(() => {
            this.loadData()
        }, 500)

        if (!this.findManual)
            this.loadData()

    }

    async loadData() {
        this.trigger('loadData')

        try {
            this.data = []
            this.resetInfiniteScroll()            
            this.query.limit = ITEMS_PER_PAGE
            this.query.page = null
            this.data = await this.service.find(this.query)
            this.trigger('loadDataEnd')
            this.update({data: this.data})
        } catch (e){
            console.log(e)
        }    
    }

    delete(item) {
        return async (e) => {
            e.preventDefault()
            const result = await $confirm({
                title: 'DELETAR',
                html: this.messages.delete(item)
            })

            if (result.value) {
                await this.service.deleteById(item._id)


                const index = this.data.indexOf(item)

                this.data.splice(index, 1)

                this.update({ data: this.data })

                this.trigger('deleted', item)

                $success({
                    text: 'Deletado com sucesso!'
                })
            }
        }
    }
    
})