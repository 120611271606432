
import { CreateService } from './base'

const GroupService = new CreateService('/group')

GroupService.TYPE = {
    GROUP: 1,
    CATEGORY: 2,
    GROUP_OF_COURSES: 3
}

GroupService.TYPE_CHOICES = {
    [GroupService.TYPE.GROUP]: 'Grupo',
    [GroupService.TYPE.CATEGORY]: 'Categoria',
    [GroupService.TYPE.GROUP_OF_COURSES]: 'Grupo de Cursos'
}


export { GroupService }