import riot from 'riot'
 riot.tag2('td-checkbox', '<div if="{!opts.inline}" class="form-group{invalid ? \' is-invalid\' : \'\'}"> <label class=" css-control css-control-{opts.size} css-control-{opts.color} css-checkbox {opts.disabled ? \'disabled\' : \'\'} "> <input autocomplete="off" ref="{opts.inputRef || \'input\'}" type="checkbox" required="{opts.required}" class="css-control-input {opts.class}" riot-value="{opts.value}" disabled="{opts.disabled}"> <span class="css-control-indicator"></span> <raw if="{opts.label}" html="{opts.label}"></raw> <yield></yield> </label> <div class="invalid-feedback animated fadeIn"> {invalidMessage} </div> </div> {opts.value} <label if="{opts.inline}" class=" css-control css-control-{opts.size} css-control-{opts.color} css-checkbox {opts.disabled ? \'disabled\' : \'\'} "> <input autocomplete="off" ref="{opts.inputRef || \'input\'}" type="checkbox" required="{opts.required}" class="css-control-input {opts.class}" riot-value="{opts.value}" disabled="{opts.disabled}"> <span class="css-control-indicator"></span> <raw if="{opts.label}" html="{opts.label}"></raw> <yield></yield> </label>', '', '', function(opts) {
        this.optionBoolean('disabled', false)
        this.option('color', 'secondary')
        this.option('size', 'sm')

        opts.model = opts.model || "values." + opts.value

        this.mixin('model')

        this.on('mount', () => {
            this.getInputRef().addEventListener('change', (e) => {
                this.update({ value: $(e.target).is(':checked') })
            })
        })

});