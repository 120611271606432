import riot from 'riot'
import _has from 'lodash/has'
import _get from 'lodash/get'
import _set from 'lodash/set'
import moment from 'moment'

const TypeValidation = {
    email: {
        message: 'E-mail inválido'
    },
    number: {
        message: 'Número inválido'
    },
    tel: {
        message: 'Telefone inválido'
    },
    url: {
        message: 'Url inválido'
    },
    date: {
        message: 'Data inválido'
    }
}

let count = 0
riot.mixin('model', class InputModel {

    init() {  

        this._firstChange = true

        let parentLoop = $(this.root).closest('[model-loop]')[0]
        if (parentLoop) parentLoop = parentLoop._tag
        this.parentRef = parentLoop || this.opts.tagModel || this.parent

        this.inputId = 'inputid-' + Date.now() + '-' + (count++)
        this.value = _get(this.parentRef, this.opts.model)
        this.getInputRef()

        if (this.opts.oninit) {
            this.opts.oninit(this.value, this)
        }


        this.on('mount', () => {
            const input = this.getInputRef()
            
            this.setViewValue(_get(this.parentRef, this.opts.model))
            this.setModelValue(_get(this.parentRef, this.opts.model))
                        
            const validType = TypeValidation[input.getAttribute('type')]

            input.addEventListener('invalid', (e) => {
                e.preventDefault()

              
                if (input.validity.valueMissing) {

                    if (this.opts.errorMessage) {
                        this.invalidMessage = this.opts.errorMessage
                    } else {
                        this.invalidMessage = (this.opts.label || "Campo") + " é obrigatório!"    
                    }

                    if (input.focus)
                        input.focus()    
                    
                } else {
                    this.invalidMessage = (
                        validType && validType.message ?
                        validType.message : 
                        input.validationMessage
                    )
                }      

                this.update({
                    invalid: true
                })

            }, false)
        })

        this.on('update', (updated) => {
            if (updated && updated.value !== undefined) {
                if(updated.value === '')
                    updated.value = null

                if(this.invalid && this.getInputRef().validity.valid)
                    this.update({invalid: false})
                    
                this.setViewValue(updated.value)
                this.setModelValue(updated.value)                
                this.parentRef.update()               
                
            }
        })

        if(this.parent){

            const parentUpdate = () => {
                
                if(this.value !== _get(this.parentRef, this.opts.model)){
                    this.setViewValue(_get(this.parentRef, this.opts.model))
                    this.setModelValue(_get(this.parentRef, this.opts.model))
                    this.value = _get(this.parentRef, this.opts.model)
                    this.update()
                }
                if(this.opts.error && _get(this.parentRef, this.opts.error)){
                    this.update({
                        invalid: true, 
                        invalidMessage: _get(this.parentRef, this.opts.error)
                    })
                } else {
                    this.update({
                        invalid: false, 
                        invalidMessage: ''
                    })
                }
            }

            this.parent.on('update', parentUpdate)

            this.on('unmount', () => { 
                this.parent.off('update', parentUpdate)
            })

            if (this.parentRef.on) {
                this.parentRef.on('update', parentUpdate)

                this.on('unmount', () => { 
                    this.parentRef.off('update', parentUpdate)
                })
            }
            
        }       
        
    }

    setViewValue(value){
        this.viewValue = value

        const input = $(this.getInputRef())

        if (input.attr('type') === 'date') {
            this.viewValue = moment(value).format('YYYY-MM-DD')
        }

        this.trigger('viewChange', this.viewValue)
       
        if (input.prop('tagName') === 'td-groupbox') {
            return;
        }
        
        if (input.attr('type') === 'file') {
            return;
        }

        if (input.attr('type') === 'date') {
            return;
        }

        if (input.attr('type') === 'checkbox') {
            if (value) {
                input.attr('checked', 'checked')
            } else {
                input.removeAttr('checked')
            }
            return;
        }

        input.val(this.viewValue ? this.viewValue : null)               
    }
    
    setModelValue(value){        
        
        this.value = value

        if (this.opts.type === 'number') {
            this.value = Number(value)
        }        

        this.trigger('modelChange', this.value)

        _set(this.parentRef, this.opts.model, this.value)

        if (!this._firstChange && this.opts.onchange) {
            this.opts.onchange(this.value, this)
        }

        if (!this._firstChange && this.opts.change) {
            this.opts.change(this.value, this)
        }


        if (this._firstChange && this.opts.start) {
            this.opts.start(this.value, this)
        }

        if (this._firstChange && this.opts.onstart) {
            this.opts.onstart(this.value, this)
        }
        

        this._firstChange = false            
    }

    getInputRef() {
        if (this.opts.inputRef)
            this.parent.refs[this.opts.inputRef] = this.refs[this.opts.inputRef || 'input']
        return this.refs[this.opts.inputRef || 'input']
    }
})