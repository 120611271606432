import riot from 'riot'
 import './testimony-a.scss'
riot.tag2('testimony-a', '<section class="container content"> <div class="row"> <div class="col-md-12 align-items-center d-flex flex-column"> <ctd-text data="{data.title}" if="{data.title}"></ctd-text> <ctd-text data="{data.description}" if="{data.description}"></ctd-text> </div> </div> <div class="row"> <div class="col-md-12"> <div ref="testimonials" class="testimonials owl-theme"> <div class="item-video" each="{video in data.testimonials}"> <div data-merge="{video.merge}"> <iframe riot-src="{video.href}" frameborder="0" allow="autoplay; fullscreen" allowfullscreen> </iframe> </div> </div> </div> </div> </div> </section>', '', '', function(opts) {

        this.data = opts.data;
        this.on('mount', () => {
            $(this.refs.testimonials).owlCarousel({
                items: 1,
                loop: true,
                video: true,
                center: true,
                stagePadding: 100,
                videoWidth: 300,
                videoHeight: 530,
                merge: true,
                nav: true,
                dots: false,
                responsive: {
                    0: {
                        items: 1,
                        videoWidth: 260,
                        videoHeight: 459,
                        stagePadding: 0,
                        center: true,
                    },
                    728: {
                        items: 3,
                        videoWidth: 300,
                        videoHeight: 530,
                        center: true,
                    }
                }
            })
        })

});