import riot from 'riot'
 riot.tag2('ctd-image', '<img if="{!tag || tag==\'img\'}" ref="root"> <div if="{tag==\'svg\'}" ref="root"></div>', '', '', function(opts) {

        this.mixin('component')

        const init = () => {
            if (this.tag === 'svg') {
                const self = this

                $.get( this.attrs.src, function( data ) {
                    const rootTag = self.svgElement || self.refs.root

                    const svgContent = data.documentElement.outerHTML
                    const svgElement = $(svgContent)

                    $.each(rootTag.attributes, function(i, attr){
                        if (attr.name != 'src')
                            svgElement.attr(attr.name, attr.value)
                    })

                    self.svgElement = svgElement

                    $(rootTag).replaceWith(svgElement)
                })
            }
        }

        init()

        this.on('update', () => {
            init()
        })

});