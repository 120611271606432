
import { CreateService } from './base'

const UserService = new CreateService('/user')

UserService.me = async () => {
    return await UserService.path('/me', {}).get()
}

UserService.current = UserService.path('/current', {})

UserService.current.setup = async (data) => {
    return await UserService.current.path('/setup', {}).post(data)
}

UserService.current.saveAnswer = async (field, index, data) => {
    return await UserService.current.path('/answer', {}).post({ field, index, data })
}

UserService.current.skipScore = async (toggle) => {
    return await UserService.current.path('/skip', {}).post({ toggle })
}


UserService.getSession = async (_id) => {
    return await UserService.path('/' + _id + '/session', {}).get()
}

UserService.book = async (data) => {
    return await UserService.path('/book', {}).post({
        list: data
    })
}

UserService.bookInvitation = async (data) => {
    return await UserService.path('/book/invitation', {}).post(data)
}

UserService.TYPE = {
    USER: 100,
    EDITOR: 500,
    MANAGER: 900,
    ADMIN: 1000
}

UserService.TYPE_CHOICES = {
    [UserService.TYPE.USER]: 'Usuário',
    [UserService.TYPE.MANAGER]: 'Gestor',
    [UserService.TYPE.EDITOR]: 'Editor',
    [UserService.TYPE.ADMIN]: 'Administrador'
}

export { UserService }