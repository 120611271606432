

export const POST_STATUS = {
    PUBLISHED: 1,
    DRAFT: 2
}

export const POST_STATUS_CHOICES = {
    [POST_STATUS.PUBLISHED]: 'Publicado',
    [POST_STATUS.DRAFT]: 'Rascunho'
}
