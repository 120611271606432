import axios from 'axios'
import store from '@td-premium/store'
import { API_HOST, API_PATH, BASE_PATH } from '@td-premium/config'

export const getApiUrl = (path = '') => {
    const host = window.location.hostname
    return `//${API_HOST ? API_HOST : host}/${API_PATH}${path}`
}

export const wrapperService = (fn) => {
    return async (...args) => {

        try {
            const result = await fn(...args)
            return result.data
        } catch (error) {
            
            if (error.response.status === 401){
                store.remove('session')
                store.remove('loggedIn')
                store.remove('token')

                if (error.response.data.error) {
                    return window.location = error.response.data.error.redirect || '/'+BASE_PATH
                }
                
                return window.location = '/'+BASE_PATH                
            }
            if (error.response) {
                if (error.response.data.error && error.response.data.error.blockAccess) {
                    store.set('active', false)
                }

                if (error.response.data.error && error.response.data.error.pending) {
                    store.set('pending', true)
                }

                if (error.response.data.error && error.response.data.error.redirect) {
                    return window.location = error.response.data.error.redirect
                }

                throw error.response.data.error
            }
            throw error
        }
    }
}

export const CreateService = function(path, opts = {}) {
 
    this.request = axios.create({
        baseURL: opts.baseURL ? opts.baseURL + path : getApiUrl(path)
    })   

    this.beseURL = opts.baseURL ? opts.baseURL + path : getApiUrl(path)

    this.setBaseURL = (url) => {
        this.beseURL = url + path
        opts.baseURL = url
    }

    this.request.interceptors.request.use((config) => {
        config.baseURL = this.beseURL 
        config.params = config.params || {}
        config.params.access_token = config.params.access_token || store.get('token')
        return config
    })

    this.path = (newPath, newOpts) => {
        return new CreateService(path + newPath, newOpts !== undefined ? {...opts, ...newOpts} : opts)
    }

    this.find = wrapperService(async (params, config = {}) => {   
        config.params = Object.assign(config.params || {}, params || {})
        return await this.request.get('/', config)
    })

    this.findById = wrapperService(async (id, params, config = {}) => {
        config.params = Object.assign(config.params || {}, params || {})
        return await this.request.get('/' + id, config)
    })

    this.findOne = wrapperService(async (params, config = {}) => {
        config.params = Object.assign(config.params || {}, params || {})
        return await this.request.get('/findOne', config)
    })

    this.updateById = wrapperService(async (id, data, config = {}) => {        
        return await this.request.put('/' + id, data, config)
    })

    this.deleteById = wrapperService(async (id, config = {}) => {        
        return await this.request.delete('/' + id, config)
    })

    this.get = wrapperService(async (config = {}) => {
        return await this.request.get('/', config)
    })

    this.post = wrapperService(async (data, config) => {
        return await this.request.post('/', data, config)
    })

    this.delete = wrapperService(async (config) => {
        return await this.request.delete('/', config)
    })

    this.patch = wrapperService(async (data, config) => {
        return await this.request.patch('/', data, config)
    })

    this.put = wrapperService(async (data, config) => {
        return await this.request.put('/', data, config)
    })
}