import moment from 'moment'
import 'moment/locale/pt-br.js'

import '@common/common'
import './pages/**/*.tag'
import './sections/**/*.tag'
import './components/**/*.tag'
import './mixins/**/*.js'

import riot from 'riot'

riot.mount('*')