
import { CreateService } from '../base'

const PlanService = new CreateService('/billing/plan')

PlanService.STATUS = {
    PUBLISHED: 1,
    HIDDEN: 2
}

PlanService.STATUS_CHOICES = {
    [PlanService.STATUS.PUBLISHED]: 'Publicado',
    [PlanService.STATUS.HIDDEN]: 'Oculto'
}

PlanService.TYPE = {
    DEFAULT: 'default',
    CORPORATION: 'corporation'
}

PlanService.TYPE_CHOICES = {
    [PlanService.TYPE.DEFAULT]: 'Padrão',
    [PlanService.TYPE.CORPORATION]: 'Corporativo'
}

PlanService.RECURRENCE_TYPE = {
    MONTHS: 'months',
    DAYS: 'days'
}

PlanService.RECURRENCE_TYPE_CHOICES = {
    [PlanService.RECURRENCE_TYPE.MONTHS]: 'Meses',
    [PlanService.RECURRENCE_TYPE.DAYS]: 'Dias'
}

PlanService.DISCOUNT_TYPE = {
    AMOUNT: 'amount',
    PERCENTAGE: 'percentage'
}

PlanService.DISCOUNT_TYPE_CHOICES = {
    [PlanService.DISCOUNT_TYPE.AMOUNT]: 'Valor (R$)',
    [PlanService.DISCOUNT_TYPE.PERCENTAGE]: 'Porcentagem (%)'
}


export { PlanService }