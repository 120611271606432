
import axios from 'axios'
import riot from 'riot'

import kebabCase from 'lodash/kebabCase'


const stylesTypes = {
    backgroundImage: (value) => {
        return 'url('+value+')'
    }
}


riot.mixin('globalFunctions', {    
    init(){
        this.on('mount', () => {
            
            setTimeout(() => {
                $(this.root).find('[href^="#"]').on('click', (e) => {
                    e.preventDefault()
                    $('.modal').modal('hide')
                    $('html, body').animate({ scrollTop: $(e.currentTarget.hash)[0].offsetTop }, 250);
                    return false
                })
            }, 1000)

        })
    },
    convertStyles(obj) {
        if (!obj) return {}

        let styles = {}

        Object.keys(obj).forEach((key) => {
            const value = obj[key]

            if (stylesTypes[key]) {
                const result = stylesTypes[key](value, key)
                if (typeof result === 'string') {
                    styles[kebabCase(key)] = result
                } else if (typeof result === 'object') {
                    styles = {...styles, ...result}
                }
            } else {
                styles[kebabCase(key)] = value
            }             
        })
        return styles
    },

    objectToStyles(obj, convert=true) {
        if (!obj) return {}

        let styles = convert ? this.convertStyles(obj) : obj        
        let stylesText = ''
        Object.keys(styles).forEach((key) => {
            stylesText += ' ' + key + ': ' + styles[key] + ';'
        })
        return stylesText
    },

    async rdConversion(tokenIds, conversion, data) {
        await axios.post('/rd/conversion', {
            tokenIds,
            conversion,
            data
        })
    },

    async pageEvent(event) {
        if (window.currentPage) {

            let events = {}
            
            try {
                events = JSON.parse(window.localStorage.events || '{}')     
            } catch (e) {
                events = {}
            }  
           
            if (!events[event]) {
                await axios.post('/event', {
                    event,
                    currentPage: window.currentPage
                })
            }

            events[event] = true
            window.localStorage.events = JSON.stringify(events)
        }        
    }
}, true)
