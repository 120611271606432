import riot from 'riot'



riot.mixin('infiniteScroll', class InfiniteScroll {
    init() {  
        
    }

    _onScroll(e) {
        let el = e.target

        if (el === document) {
            el = document.documentElement
        }
        
        const percent = (el.scrollTop + el.clientHeight) / el.scrollHeight * 100

        if (percent > 80) {
            this.nextPage()
        }
    }

    async nextPage(){
        if (this._loading || !this._initScroll) {
            return
        }

        this._page++

        this.update({ _loading: true })
        
        this.trigger('nextPage')
    }

    infiniteScroll(el = window) {
        this.resetInfiniteScroll()

        this._el = el

        if (this._onScroll)
            this.destroyInfiniteScroll()

        this._onScroll = this._onScroll.bind(this)           

        el.addEventListener('scroll', this._onScroll)

        this.on('unmount', () => {
            this.destroyInfiniteScroll()
        })
    }

    destroyInfiniteScroll() {
        if (this._el)
            this._el.removeEventListener('scroll', this._onScroll)
    }
   

    resetInfiniteScroll() {        
        this._initScroll = true
        this._loading = false
        this._page = 0
    }
})