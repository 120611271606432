import riot from 'riot'
 import axios from 'axios'
riot.tag2('td-select', '<div class="form-group {invalid ? \'is-invalid\' : \'\'}"> <label if="{opts.label}">{opts.label}</label> <div class="input-group"> <div class="input-group-prepend"> <yield from="prepend"></yield> </div> <select autocomplete="off" ref="{opts.inputRef || \'input\'}" required="{opts.required}" multiple="{opts.multiple}" placeholder="{opts.placeholder}" disabled="{opts.disabled}" class="form-control {opts.class}"> <yield></yield> </select> <div ref="{\'options\'}" style="display: none;"> <yield from="html-options"></yield> </select> </div> <div class="invalid-feedback animated fadeIn"> {invalidMessage} </div> </div>', 'td-select .select2-container,[data-is="td-select"] .select2-container{ display: block; width: 100% !important; }', '', function(opts) {

        this.mixin('model')

        const formatData = (data) => {
            if (!data) return opts.extraData || []

            this._data = [...(opts.extraData||[]), ...data]

            if (opts.dataExclude) {
                this._data = this._data.filter((o) => {
                    for (let i = 0; i < opts.dataExclude.length; i++) {
                        const exclude = opts.dataExclude[i];
                        const value = o[opts.dataValue] != undefined ? o[opts.dataValue] : o
                        if (exclude === value || exclude._id === value) {
                            return false
                        }
                    }
                    return true
                })
            }

            this._data = this._data.map((o) => {
                if (o.__formatted) return o

                return {
                    id: o[opts.dataValue] != undefined ? o[opts.dataValue] : o,
                    text: o[opts.dataText] != undefined ? o[opts.dataText] : o,
                    data: o,
                    __formatted: true
                }
            })

            return this._data
        }

        this.on('modelChange', (data) => {
            const input = $(this.getInputRef())

            if (data != undefined && opts.service) {

                formatData(Array.isArray(data) ? data : [data]).forEach((o) => {
                    if (opts.multiple) {
                        if (!input.find('option[value="'+ o.id +'"]').length) {
                            input.append(new Option(o.text, o.id, true, true))
                        }
                    } else {
                        if (!input.find('option[value="'+ o.id +'"]').length && (o.id === data || o.id === data[opts.dataValue])) {
                            input.append(new Option(o.text, o.id, true, true))
                        }
                    }
                })

                input.trigger('change')

            }  else if (!opts.multiple) {
                setTimeout(() => {
                    $(this.getInputRef()).val(data)
                    $(this.getInputRef()).trigger('change')
                })
            }

        })

        this.on('viewChange', () => {
            if (this.viewValue != undefined && Array.isArray(this.viewValue)) {
                this.viewValue = this.viewValue.map(o=> {
                    return o[opts.dataValue] != undefined ? o[opts.dataValue] : o
                })
            }
        })

        this.shouldUpdate = (_, nextOpts) => {

            if (opts.coupon != nextOpts.coupon) {
                if (opts.html) {
                    setTimeout(() => {
                        console.log('nani')
                        $(this.getInputRef()).empty().select2({
                            ...this.options,
                            data: mountHTMLdata()
                        })
                    })
                }
            }

            return true
        }

        this.on('updated', () => {

            if (!opts.multiple && !$(this.getInputRef()).val() && this.value != undefined) {
                $(this.getInputRef()).val(this.value)
                $(this.getInputRef()).trigger('change')
            }

        });

        const mountHTMLdata = () => {
            const items = [...this.refs.options.querySelectorAll('.option')]
            return items.map((el) => {
                return {
                    id: el.getAttribute('data-id') || el._tag.item.id,
                    text: el.getAttribute('data-text') || el._tag.item.text || el.outerHTML,
                    html: el.outerHTML
                }
            })
        }

        this.on('mount', () => {
            const options = opts.options || {};
            if (opts.service) {
                const service = opts.service

                const limit = 25
                let morePages = true

                options.ajax = {
                    processResults: function (data, params) {
                        if (data.length < limit) {
                            morePages = false
                        } else {
                            morePages = true
                        }

                        return {
                            results: formatData(data),
                            pagination: {
                                more: morePages
                            }
                        }
                    },
                    data: function (params) {
                        return {
                            [opts.dataText + '__search']: params.term,
                            page: params.page - 1 || 0,
                            limit: limit,
                            ...opts.query
                        }
                    },
                    transport: (params, success, failure) => {
                        let abort
                        service.find(params.data, {
                            cancelToken: new axios.CancelToken(function executor(c) {
                                abort = c
                            })
                        }).then(success, failure)

                        return { abort }
                    }
                }
            }

            if (opts.html) {
                options.data = mountHTMLdata()
                options.escapeMarkup = function(m) {
                    return '<span>' + m + '</span>'
                }
                options.templateResult = function(data) {
                    return data.html
                }
            }

            this.options = {
                placeholder: this.opts.placeholder,
                allowClear: this.opts.allowclear,
                ...options
            }

            $(this.getInputRef()).select2(this.options)

            $(this.getInputRef()).on('select2:unselect', (e) => {
                const value = $(this.getInputRef()).val()
                if (opts.multiple) {
                    this.update({ value: value === [] ? null : value })
                } else {
                    this.update({ value: value === '' ? null : value })
                }
            })

            $(this.getInputRef()).on('select2:select', (e) => {
                const value = $(this.getInputRef()).val()
                if (opts.multiple) {
                    this.update({ value: value === [] ? null : value })
                } else {
                    this.update({ value: value === '' ? null : value })
                }
            })

        })

        this.on('unmount', () => {
            $(this.getInputRef()).select2('destroy')
        });

});