
import { CreateService } from './base'

const ContentService = new CreateService('/content')

ContentService.PRIVACY = {
    FREE: 1,
    PAID: 2
}

ContentService.PRIVACY_CHOICES = {
    [ContentService.PRIVACY.FREE]: 'Grátis',
    [ContentService.PRIVACY.PAID]: 'Pago'
}

ContentService.STATUS = {
    PUBLISHED: 1,
    DRAFT: 2
}

ContentService.STATUS_CHOICES = {
    [ContentService.STATUS.PUBLISHED]: 'Publicado',
    [ContentService.STATUS.DRAFT]: 'Rascunho'
}

ContentService.TYPE = {
    VIDEO: 1,
    LIVE: 2,
    TEXT: 3
}

ContentService.TYPE_CHOICES = {
    [ContentService.TYPE.VIDEO]: 'Video',
    [ContentService.TYPE.LIVE]: 'Live',
    [ContentService.TYPE.TEXT]: 'Texto'
}

ContentService.history = async (query) => {
    return await ContentService.path('/history', {}).get(query)
}

ContentService.myList = async (query) => {
    return await ContentService.path('/mylist', {}).get(query)
}

export { ContentService }