import riot from 'riot'

import _debounce from 'lodash/debounce'
import _has from 'lodash/has'
import _get from 'lodash/get'
import _set from 'lodash/set'
import _merge from 'lodash/merge'
import _isObject from 'lodash/isObject'


riot.mixin('baseForm', class BaseForm {

    init() {
        this.error = this.error || {}
        this.data = this.data || {}
        this.loadingData = false
        this._defaultData = JSON.parse(JSON.stringify(this.data))

        if (this.opts.edit){
            this.loadingData = true
            this.loadData(this.opts.edit)
        }
    }

    reset() {
        this.data = JSON.parse(JSON.stringify(this._defaultData))
        this.error = {}
    }

    back(urlDefault) {
        return () => {
            if (history.length > 1) return history.back()
            page(urlDefault)
        }
    }

    async loadData(id) {
        const data = await this.service.findById(id)
        this.loadingData = false
        this.data = _merge(this.data, data)
        this.update({ data: this.data })
        this.trigger("loadData", this.data)
    }

    async delete(e) {
        e.preventDefault()
        const result = await $confirm({
            title: 'DELETAR',
            html: this.messages.delete(this.data)
        })

        if (result.value) {
            const response = await this.service.deleteById(this.data._id)
            $success({
                text: 'Deletado com sucesso!'
            })
            this.trigger("deleted", response)
        }
    }

    async onSubmit(e) {
        e.preventDefault()

        if(this.loading)
            return
        
        try {

            this.loading = true

            this.trigger("beforeSave", this.data)
            let response
            if(this.opts.edit){
                response = await this.service.updateById(this.opts.edit, this.data)
                $success({
                    text: this.messages.update()
                })
                await this.loadData(this.opts.edit)
            }else{
                response = await this.service.post(this.data)
                this.reset(response)
                $success({        
                    text: this.messages.create() 
                })
            }

            this.trigger("afterSave", response)

            
        } catch (error) {

            if (_isObject(error.message)) {
                this.error = error.message
            } else {
                $error({        
                    text: error.message
                })
            }

            
        }

        this.update({ loading: false  })
    }
})