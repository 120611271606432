import riot from 'riot'

import _get from 'lodash/get'
import _has from 'lodash/has'
import moment from 'moment'

riot.mixin('utils', class Utils {

    init() {
        this._id = 'id-' + Date.now() + '-' + this._riot_id
    }

    option(key, defaultValue) {
        return this.opts[key] = _get(this.opts, key, defaultValue)        
    }

    optionBoolean(key) {
        if (this.opts[key] === undefined && _has(this.opts, key)) {
            return this.opts[key] = true
        } else {
            return this.option(key, false)
        }
    }

    date(text) {
        return moment(
            new Date(text)
        ).format('L')
    }
}, true)