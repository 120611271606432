import riot from 'riot'
 import './thank-you.scss'
import courses from '@root/courses'
riot.tag2('td-thank-you', '<div if="{thankYou}" class="bg-image" riot-style="{bgStyles}"> <div class="container content content-full content-top pt-20 h-100 d-flex flex-column justify-content-center align-items-center"> <ctd-text data="{thankYou.title}" if="{thankYou.title}"></ctd-text> <ctd-text data="{thankYou.message}" if="{thankYou.message}"></ctd-text> </div> </div>', '', '', function(opts) {

        this.mixin('styles')

        const currentPage = window.currentPage || opts.page

        const init = async () => {

            const params = TD.GetParams()

            if (!params || !params['payment_status'] || params['payment_status'] === 'rejected') {
                return window.location = './'
            }

            if (!courses[currentPage]) {
                return window.location = 'https://transformacaodigital.com'
            }

            const data = await courses[currentPage]()

            if (fbq) {
                fbq('track', 'Purchase', {
                    value: data.productPrice,
                    currency: 'BRL',
                    contents: [
                        {
                            quantity: 1,
                            id: 'compra'
                        }
                    ],
                    content_ids: currentPage
                })
            }

            this.createStyles({
                '@global': data.global.styles
            })

            this.bgStyles = this.convertStyles(data.thankYou.bgStyles || {})

            this.thankYou = data.thankYou

            this.update()

        }

        init()

});