
import moment from 'moment'
import 'moment/locale/pt-br.js'

moment.locale('pt-br')

import './assets/css/themes/pulse.min.css'


import './assets/icons/style.css'
import './styles/*.css'
import './mixins/**/*.js'
import './components'


